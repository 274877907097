// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhoneVerification-module__QaWRoksMCw5w6M3DHMSs {\n  flex-grow: 1;\n}\n\n.PhoneVerification-module__DDfHMsq1hNoybIMJPAAN {\n  font-weight: bolder;\n  padding: 0 4px;\n}\n", "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/tabs/PhoneVerification/PhoneVerification.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".input {\n  flex-grow: 1;\n}\n\n.telegram-code {\n  font-weight: bolder;\n  padding: 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "PhoneVerification-module__QaWRoksMCw5w6M3DHMSs",
	"telegram-code": "PhoneVerification-module__DDfHMsq1hNoybIMJPAAN"
};
export default ___CSS_LOADER_EXPORT___;
