// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WrongTeamStub-module___w004YsulkI4OtrV6n7w {\n  margin: 50px auto;\n  text-align: center;\n  width: 400px;\n}\n\n.WrongTeamStub-module__cpMMWclc_gqIJieSW7nO {\n  color: var(--warning-text-color);\n}\n\n.WrongTeamStub-module__ork4H7N55eS7powV_1GQ {\n  color: white;\n  margin-right: 4px;\n  padding-top: 6px;\n}\n\n.WrongTeamStub-module__H8czuSzdPbpOZDJSvzVA {\n  margin-top: 32px;\n}\n", "",{"version":3,"sources":["webpack://./components/NotFoundInTeam/WrongTeamStub.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".not-found {\n  margin: 50px auto;\n  text-align: center;\n  width: 400px;\n}\n\n.error-code {\n  color: var(--warning-text-color);\n}\n\n.change-team-icon {\n  color: white;\n  margin-right: 4px;\n  padding-top: 6px;\n}\n\n.return-to-list {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"not-found": "WrongTeamStub-module___w004YsulkI4OtrV6n7w",
	"error-code": "WrongTeamStub-module__cpMMWclc_gqIJieSW7nO",
	"change-team-icon": "WrongTeamStub-module__ork4H7N55eS7powV_1GQ",
	"return-to-list": "WrongTeamStub-module__H8czuSzdPbpOZDJSvzVA"
};
export default ___CSS_LOADER_EXPORT___;
