// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SourceCode-module__iyjr0IbHQ9pnPMx8aKj0 {\n  position: relative;\n  width: 100%;\n}\n\n.SourceCode-module__M103XaxYbuQjY6AAGkUF {\n  overflow-y: auto;\n}\n\n.SourceCode-module__WkDEbodM5LF0KUZR3zUF {\n  max-height: 400px;\n}\n\n.SourceCode-module__iyjr0IbHQ9pnPMx8aKj0 .SourceCode-module__BQsIOECZUjEjZQuVckhH {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  opacity: 0;\n  transition: opacity 0.2s ease;\n}\n\n.SourceCode-module__iyjr0IbHQ9pnPMx8aKj0:hover .SourceCode-module__BQsIOECZUjEjZQuVckhH {\n  opacity: 1;\n}\n\n.SourceCode-module__iyjr0IbHQ9pnPMx8aKj0 pre {\n  border-radius: 2px;\n  padding: 12px 20px;\n}\n", "",{"version":3,"sources":["webpack://./components/SourceCode/SourceCode.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".root {\n  position: relative;\n  width: 100%;\n}\n\n.scroller {\n  overflow-y: auto;\n}\n\n.scroller_max-height {\n  max-height: 400px;\n}\n\n.root .button {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  opacity: 0;\n  transition: opacity 0.2s ease;\n}\n\n.root:hover .button {\n  opacity: 1;\n}\n\n.root pre {\n  border-radius: 2px;\n  padding: 12px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SourceCode-module__iyjr0IbHQ9pnPMx8aKj0",
	"scroller": "SourceCode-module__M103XaxYbuQjY6AAGkUF",
	"scroller_max-height": "SourceCode-module__WkDEbodM5LF0KUZR3zUF",
	"button": "SourceCode-module__BQsIOECZUjEjZQuVckhH"
};
export default ___CSS_LOADER_EXPORT___;
